import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
  notification,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-signup.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { baseUrl } from "../config";

function Profile() {
  const { userId } = useParams();
  const [imageURL, setImageURL] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    getUser();
  }, []); // Update data when page or page size changes

  async function getUser() {
    try {
      const response = await axios.get(`${baseUrl}/api/user/read/${userId}`);
      if (response.status === 200) {
        setUserData(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight',
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight',
      });
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={baseUrl + '/' + userData?.photo} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{userData?.name}</h4>
                  <p>{userData?.email}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Radio.Group defaultValue="a">
                <Radio.Button value="a">OVERVIEW</Radio.Button>
                <Radio.Button value="b">TEAMS</Radio.Button>
                <Radio.Button value="c">PROJECTS</Radio.Button>
              </Radio.Group> */}
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24 ">
          <Card
            bordered={false}
            // title={<h6 className="font-semibold m-0">Basic Information</h6>}
            className="header-solid h-full card-profile-information"
            // extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions title="Basic Information" style={{ marginTop: 30 }}>
              <Descriptions.Item label="Education" span={3}>
                {userData?.education}
              </Descriptions.Item>
              <Descriptions.Item label="Hobbies" span={3}>
                {userData?.hobbies?.join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label="Love Style" span={3}>
                {userData?.loveStyle?.join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label="Personality Type" span={3}>
                {userData?.personalityType}
              </Descriptions.Item>
              <Descriptions.Item label="Workout" span={3}>
                {userData?.workout}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            // title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            // extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions title="Profile Information" style={{ marginTop: 30 }}>
              <p className="text-dark">
                {" "}
                {userData?.introduction}{" "}
              </p>
            </Descriptions>

            <hr className="my-25" />
            <Descriptions title="Personal Information">
              <Descriptions.Item label="Mobile" span={3}>
                {userData?.countryCode + userData?.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {userData?.email}
              </Descriptions.Item>
              <Descriptions.Item label="DOB" span={3}>
                {new Date(userData?.dob).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'long', // This will display the full month name
                  year: 'numeric',
                })}
              </Descriptions.Item>
              {/* <Descriptions.Item label="University" span={3}>
                {userData?.university?.name}
              </Descriptions.Item> */}
              <Descriptions.Item label="Gender" span={3}>
                {userData?.gender}
              </Descriptions.Item>
              <Descriptions.Item label="Height" span={3}>
                {userData?.height}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        {/* <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Subscription Plan</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions.Item label="Plan" span={3}>
              Basic Plan
            </Descriptions.Item>
          </Card>
        </Col> */}
      </Row>

    </>
  );
}

export default Profile;
