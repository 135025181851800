import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    notification,
    Modal,
    Button,
    Image
} from "antd";
import axios from "axios";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { baseUrl } from "../../config";

const { confirm } = Modal;
const { Title } = Typography;

// table code start
const columns = [
    {
        title: "#",
        key: "key",
        dataIndex: "key",
    },
    {
        title: "Privacy",
        dataIndex: "privacy",
        key: "privacy"
    },
    {
        title: "Terms & Condition",
        dataIndex: "termsCondition",
        key: "termsCondition"
    },
    {
        title: "Offer Image",
        key: "image",
        dataIndex: "image",
    },
    {
        title: "Status",
        key: "status",
        dataIndex: "status",
    },
    {
        title: "Action",
        key: "action",
        dataIndex: "action",
    }
];



function SettingList() {

    const [userList, setUserList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [privacy, setPrivacy] = useState('');
    const [titleName, setTitleName] = useState();

    const showModal = (privacy, name) => {
        setTitleName(name);
        setPrivacy(privacy)
        setModalVisible(true);
    };

    const handleOk = () => {
        setModalVisible(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        getList()
    }, [])


    async function getList() {
        try {

            // Make API call to submit form data
            const response = await axios.get(`${baseUrl}/api/setting/listAll`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setUserList(response.data.result);
            } else {

                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight' // You can adjust the placement as needed
                });
            }
            // Handle success response from the API
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight' // You can adjust the placement as needed
            });
            // Handle error response from the API
        }
    };

    // async function handleDelete(id) {
    //     try {
    //         const response = await axios.delete(`${baseUrl}/api/setting/delete/${id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
    //             },
    //         });
    //         if (response.status === 200) {
    //             getList();
    //             notification.success({
    //                 message: 'Success',
    //                 description: 'Report element deleted successfully!',
    //                 placement: 'topRight'
    //             });
    //         } else {
    //             notification.info({
    //                 message: 'Info',
    //                 description: response.data.message,
    //                 placement: 'topRight'
    //             });
    //         }
    //     } catch (error) {
    //         console.error("API error:", error);
    //         notification.info({
    //             message: 'Info',
    //             description: error.response?.data?.message,
    //             placement: 'topRight'
    //         });
    //     }
    // };

    // const showDeleteConfirm = (id) => {
    //     confirm({
    //         title: 'Are you sure you want to delete this item?',
    //         icon: <ExclamationCircleOutlined />,
    //         content: 'This action cannot be undone.',
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk() {
    //             handleDelete(id);
    //         },
    //     });
    // };

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Setting"
                            extra={
                                <>
                                    {/* <Link className="custom-btn" to="/setting/add">Add</Link> */}
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={userList.map((user, index) => ({
                                        key: index + 1,
                                        privacy: (
                                            <Button type="primary" onClick={() => showModal(user.privacy, 'Privacy')}>
                                                <EyeOutlined />
                                            </Button>

                                        ),
                                        image: (
                                            <Image
                                                width={50}
                                                src={baseUrl + '/' + user.file}
                                            />
                                        ),
                                        termsCondition: (
                                            <Button type="primary" onClick={() => showModal(user.termsCondition, 'Terms & Condition')}>
                                                <EyeOutlined />
                                            </Button>

                                        ),
                                        status: (
                                            <span type="primary" className={user.enabled ? 'text-success' : 'text-danger'}>
                                                {user.enabled ? 'Active' : 'Inactive'}
                                            </span>
                                        ),
                                        action: (
                                            <div className="button-container">
                                                <Link to={`/setting/update/${user._id}`} className="update-btn">
                                                    <EditOutlined />
                                                </Link>
                                            </div>
                                        )
                                    }))}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                title={`${titleName} Policy`}
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="author-info">
                    <div dangerouslySetInnerHTML={{ __html: privacy }} />
                </div>
            </Modal>
        </>
    );
}

export default SettingList;
