import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Typography, notification, Form, Input } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../config";

const { Title } = Typography;
const { Item } = Form;

function UserProfile() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/user/read`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
            if (response.status === 200) {
                setUserData(response.data.result);
                form.setFieldsValue({
                    email: response.data.result.email,
                    password: "",
                });
            } else {
                notification.info({
                    message: "Info",
                    description: response.data.message,
                    placement: "topRight",
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: "Info",
                description: error.response?.data?.message,
                placement: "topRight",
            });
        }
    };

    const onFinish = async (values) => {
        try {
            const response = await axios.patch(
                `${baseUrl}/api/admin/profile/update`,
                values,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            if (response.status === 200) {
                notification.success({
                    message: "Success",
                    description: "Profile updated successfully!",
                    placement: "topRight",
                });
                history.push("/profile");
            } else {
                notification.info({
                    message: "Info",
                    description: response.data.message,
                    placement: "topRight",
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: "Info",
                description: error.response?.data?.message,
                placement: "topRight",
            });
        }
    };

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Update Profile"
                    >
                        <Form
                            style={{ padding: "20px" }}
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            { required: true, message: "Please enter your email" },
                                            { type: "email", message: "Invalid email address" },
                                        ]}
                                    >
                                        <Input className="input-full-height" />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={12} lg={12}>
                                    <Item
                                        label="New Password"
                                        name="password"
                                        rules={[
                                            { required: true, message: "Please enter a new password" },
                                            { min: 6, message: "Password must be at least 6 characters" },
                                        ]}
                                    >
                                        <Input.Password className="input-full-height" />
                                    </Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} lg={8}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            Update Profile
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default UserProfile;
